import { endpoints } from "@/constants";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { makeLearnerAPIDTO, makeUserTrainingsDTO } from "./userTraining.dto";

const fetchUserTrainingList = async ({ tenantId }) => {
  const result = await HTTP(
    "get",
    endpoints.USER_TRAINING_LIST,
    null,
    makeAuthorizationHeader({ tenantId })
  );
  result.data = makeUserTrainingsDTO({
    trainings: result?.data?.data
  });
  return result;
};

const addNewTrainingLearner = async ({ tenantId, learner } = {}) => {
  const response = await HTTP(
    "post",
    endpoints.ADD_USER_TRAINING_LEARNER,
    makeLearnerAPIDTO(learner),
    makeAuthorizationHeader({ tenantId })
  );
  return response;
};

export const userTrainingService = {
  addNewTrainingLearner,
  fetchUserTrainingList
};
