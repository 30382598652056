<template>
  <section>
    <BaseLoader v-if="isLoadingThirdPartyTrainingDetails" />
    <template v-else>
      <ProfileHeader
        :heading="trainingData.name"
        :subheading="trainingData.description"
        :CTAOptions="CTAOptions"
        @click="handleCTA"
      />
      <InputErrorMessage :error="errorMessage" />
      <AddTrainingLearner
        v-if="showAddNewLearner"
        :course-id="courseId"
        :third-party-id="thirdPartyId"
        @click="onAddLearner"
      />
      <StickyTable
        :table-headers="tableHeaders"
        :table-rows="tableRows"
        data-test-id="learner__table"
      />
    </template>
  </section>
</template>

<script>
import BaseLoader from "@/atoms/BaseLoader/BaseLoader";
import StickyTable from "@/molecules/StickyTable/StickyTable";
import BaseText from "@/atoms/BaseText/BaseText";
import BaseBadge from "@/atoms/BaseBadge/BaseBadge";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";
import ProfileHeader from "@/molecules/ProfileHeader/ProfileHeader";
import AddTrainingLearner from "@/organisms/AddTrainingLearner/AddTrainingLearner";

import {
  trainingLearnersTableHeaders,
  actionName,
  mutationName,
  trainingLearnersKeysDTO,
  icons,
  themes,
  defaultIcons,
  views,
  DOMEvents
} from "@/constants";
import { mapActions, mapState, mapMutations } from "vuex";
import InputErrorMessage from "@/molecules/InputErrorMessage/InputErrorMessage";
import { getTrainingStatusTheme } from "@/utils";

export default {
  name: "ThirdPartyTrainingDetails",
  components: {
    StickyTable,
    BaseLoader,
    InputErrorMessage,
    ProfileHeader,
    AddTrainingLearner
  },
  data() {
    return {
      errorMessage: "",
      showAddNewLearner: false,
      themes,
      views
    };
  },
  props: {
    thirdPartyId: {
      type: Number,
      default: 0
    },
    courseId: {
      type: Number,
      default: 0
    },
    isSupplierUser: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    await this.fetchTrainingDetailsWrapper();
  },
  computed: {
    ...mapState({
      thirdPartyTraining: (state) => state.thirdParty.thirdPartyTraining,
      isLoadingThirdPartyTrainingDetails: (state) =>
        state.thirdParty.isLoadingThirdPartyTrainingDetails
    }),
    trainingData() {
      return {
        name: this.thirdPartyTraining?.title,
        description: this.thirdPartyTraining?.description
      };
    },
    CTAOptions() {
      if (this.isSupplierUser) {
        return {
          ...defaultIcons.EDIT,
          value: "Add new learner",
          icon: null
        };
      } else
        return {
          ...defaultIcons.EDIT,
          value: "Back to training list",
          icon: null
        };
    },
    tableHeaders() {
      return this.thirdPartyTraining?.learners?.length
        ? Object.keys(trainingLearnersTableHeaders).reduce((acc, key) => {
            if (trainingLearnersTableHeaders[key]) {
              acc.push({
                value: key,
                text: trainingLearnersTableHeaders[key]
              });
            }
            return acc;
          }, [])
        : [];
    },
    tableRows() {
      return this.thirdPartyTraining?.learners?.length
        ? this.thirdPartyTraining?.learners.map((training) =>
            Object.keys(trainingLearnersTableHeaders).reduce(
              (acc, trainingKey) => {
                if (trainingKey === trainingLearnersKeysDTO.STATUS) {
                  acc[trainingKey] = {
                    component: BaseBadge,
                    componentOptions: {
                      theme: getTrainingStatusTheme(training[trainingKey]),
                      text: training[trainingKey]
                    }
                  };
                } else if (
                  trainingKey === trainingLearnersKeysDTO.REMINDER_SENT
                ) {
                  acc[trainingKey] = {
                    component: BaseIcon,
                    componentOptions: {
                      icon: training[trainingKey] ? icons.CHECK : icons.MINUS,
                      size: 16,
                      theme: themes.LIGHT_GREY_INVERSE
                    },
                    styles: {
                      textAlign: "-webkit-center"
                    }
                  };
                } else {
                  acc[trainingKey] = {
                    component: BaseText,
                    componentOptions: {
                      tag: "span",
                      text: training[trainingKey]
                    }
                  };
                }
                return acc;
              },
              {}
            )
          )
        : [];
    }
  },
  beforeDestroy() {
    this.resetThirdPartyTraining();
  },
  methods: {
    ...mapActions({
      fetchTrainingDetails:
        actionName.THIRD_PARTY.FETCH_THIRD_PARTY_TRAINING_DETAILS
    }),
    ...mapMutations({
      resetThirdPartyTraining:
        mutationName.THIRD_PARTY.RESET_THIRD_PARTY_TRAINING
    }),
    async fetchTrainingDetailsWrapper() {
      try {
        this.errorMessage = "";
        await this.fetchTrainingDetails({
          thirdPartyId: this.thirdPartyId,
          courseId: this.courseId
        });
      } catch ({ message = "" }) {
        this.errorMessage = message;
      }
    },
    onBackClick() {
      this.$emit(DOMEvents.CLICK, {
        view: views.LIST
      });
    },
    onAddLearner() {
      this.showAddNewLearner = !this.showAddNewLearner;
    },
    handleCTA() {
      if (this.isSupplierUser) {
        this.onAddLearner();
      } else {
        this.onBackClick();
      }
    }
  }
};
</script>
