<template>
  <div>
    <b-modal
      scrollable
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      v-model="showRequestSupportModal"
      size="xl"
      title="Request Support"
    >
      <div>
        <div v-if="!submiting && !responseSuccess">
          <p>
            <strong>Full Name:</strong>
            {{ fullName }}
          </p>
          <p>
            <strong>Email:</strong>
            {{ email }}
          </p>
          <b-form-group>
            <label class="control-label">
              <strong>Please enter your query here:</strong>
            </label>
            <b-form-textarea
              v-model="v$.form.queryValue.$model"
              :state="
                v$.form.queryValue.$dirty ? !v$.form.queryValue.$error : null
              "
              placeholder="Let us know how we can help..."
              rows="6"
              max-rows="6"
            ></b-form-textarea>
            <div v-if="v$.form.queryValue.$error" class="invalid-feedback">
              <span v-if="!v$.form.queryValue.required"
                >All informations are required.</span
              >
            </div>
          </b-form-group>
        </div>
        <div v-else-if="submiting && !responseSuccess">
          <p>Send your request......</p>
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>
        <div v-else>
          <p class="my-2 text-center">
            Your request for support successfully sent.
            <br />
          </p>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button
          v-if="!submiting"
          variant="outline-danger"
          @click="resetModal"
          >{{ responseSuccess ? "Close" : "Cancel" }}</b-button
        >
        <b-button
          v-if="!submiting && !responseSuccess"
          variant="outline-success"
          @click="checkValidation"
          >Submit</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { EventBus } from "@/event-bus.js";
import { HTTP, makeAuthorizationHeader } from "@/utils";
import { mapGetters, mapState } from "vuex";
import { getterName } from "@/constants";

export default {
  name: "RequestSupportModal",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      showRequestSupportModal: false,
      form: {
        queryValue: null
      },
      submiting: false,
      responseSuccess: false
    };
  },
  created() {
    EventBus.on("onShowRequestSupportModal", () => {
      this.showModal();
    });
  },
  computed: {
    ...mapState({
      email: (state) => state.user.email
    }),
    ...mapGetters({
      fullName: getterName.USER.GET_FULL_NAME
    })
  },
  validations: {
    form: {
      queryValue: {
        required
      }
    }
  },
  methods: {
    showModal() {
      this.showRequestSupportModal = true;
    },
    resetModal() {
      this.showRequestSupportModal = false;
      this.form.queryValue = null;
      this.submiting = false;
      this.responseSuccess = false;
      this.v$.$reset();
    },
    checkValidation() {
      this.v$.form?.$touch();
      if (this.v$.form.$errors?.length) {
        return false;
      } else {
        this.sendRequestSupportApiCall();
      }
    },
    async sendRequestSupportApiCall() {
      let formData = {
        name: this.fullName,
        email: this.email,
        description: this.form.queryValue
      };
      try {
        this.submiting = true;
        this.isLoading = true;
        let result = await HTTP(
          "post",
          "v1/email/support",
          formData,
          makeAuthorizationHeader()
        );
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = true;
        if (result.data && result.data.success == 1) {
          this.makeToster();
        }
      } catch (err) {
        this.submiting = false;
        this.isLoading = false;
        this.responseSuccess = false;
        return [err];
      }
    },
    makeToster() {
      this.resetModal();
      this.$bvToast.toast("Your request for support successfully sent.", {
        title: "Request Support",
        variant: "success",
        autoHideDelay: 2000,
        appendToast: false
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.control-label:after {
  content: "*";
  color: $spanish-red;
  margin-left: 5px;
  font-size: 20px;
}
</style>
