<template>
  <div
    :class="['sidebar', sideBarCssClasses]"
    @mouseenter="onSideBarVisibility(true)"
    @mouseleave="onSideBarVisibility(false)"
    @focusin="onSideBarVisibility(true)"
    @focusout="onSideBarVisibility(false)"
  >
    <div class="sidebar__wrapper">
      <div class="sidebar__wrapper-menu">
        <div :class="['sidebar__arrow', sideBarArrowCssClasses]">
          <button
            type="button"
            class="sidebar__arrow-button"
            @click="onToggleCollapse"
          >
            <font-awesome-icon size="lg" icon="chevron-circle-left" />
          </button>
        </div>
        <sidebar-menu
          class="sidebar__menu bg-light"
          :data-test-id="dataTestId"
          :menu="makeMenu"
          width="225px"
          :relative="true"
          theme="white-theme"
          @item-click="onItemClick"
        >
          <span slot="dropdown-icon">
            <BaseIcon
              icon="chevron-right"
              :size="12"
              :theme="themes.LIGHT_GREY_INVERSE"
            />
          </span>
        </sidebar-menu>
      </div>
      <div class="sidebar--bottom-shadow"></div>
    </div>
  </div>
</template>

<script>
import {
  localStorageItem,
  timers,
  urls,
  mutationName,
  esgTexts,
  routeNames,
  analyticsConfigurationViews,
  userViewType,
  themes,
  getterName
} from "@/constants";
import { hasAccessToRoute } from "@/utils";
import { mapGetters, mapState, mapMutations } from "vuex";
import BaseIcon from "@/atoms/BaseIcon/BaseIcon";

export default {
  name: "Eb2Sidebar",
  components: {
    BaseIcon
  },
  data() {
    return {
      sideBarCssClasses: "",
      sideBarCssClassAnimation: "sidebar--slide-left",
      sideBarCssClassFinishedAnimation: "sidebar--closed",
      sideBarArrowCssClasses: "",
      sideBarArrowCssClassAnimation: "sidebar__arrow--rotate",
      themes
    };
  },
  computed: {
    ...mapState({
      isSidebarOpened: (state) => state.isSidebarOpened,
      newSidebarData: (state) => state.newSidebarData
    }),
    ...mapGetters({
      getCompanyViewsWithPermission:
        getterName.COMPANY.GET_COMPANY_VIEWS_WITH_PERMISSION
    }),
    isSideBarClosed() {
      return this.sideBarCssClasses.includes(
        this.sideBarCssClassFinishedAnimation
      );
    },
    dataTestId() {
      return this.isSideBarClosed
        ? "sidebar__menu--closed"
        : "sidebar__menu--open";
    },
    makeAnalyticsMenuItems() {
      const filteredMenu = this.newSidebarData?.analyticsConfig?.filter(
        (menu) =>
          parseInt(menu.isActive) === 1 &&
          menu.title !== analyticsConfigurationViews.APRI
      );

      return (
        filteredMenu?.map((item) => ({
          title: item?.title,
          child: item?.items
            ?.filter((childItem) => parseInt(childItem?.isActive) === 1)
            ?.map((childItem) =>
              this.setAnalyticsMenuItemOptions({
                menuTitle: item?.title,
                childItem
              })
            )
        })) || []
      );
    },
    companyViewMenuItems() {
      return (
        this.getCompanyViewsWithPermission?.map((companyView) => {
          const viewType = companyView?.viewType || companyView?.view_type;

          switch (parseInt(viewType)) {
            case userViewType.THIRD_PARTY:
              return {
                title: companyView?.title,
                href: urls.MANAGE_THIRD_PARTY_ID(companyView?.id),
                icon: "fa fa-tasks"
              };
            case userViewType.EVENT_LOG:
              return {
                title: companyView?.title,
                href: urls.EVENT_LOG_ID(companyView?.id),
                icon: "fa fa-calendar-plus"
              };
            case userViewType.USER_VIEW_ANALYTICS:
              return {
                title: companyView?.title,
                href: urls.ANALYTICS_USER_VIEW_ID(companyView?.id),
                icon: "fa fa-chart-bar"
              };
            case userViewType.BI_DASHBOARD:
              return {
                title: companyView?.title,
                href: urls.BI_DASHBOARD(companyView?.id),
                icon: "fa fa-cubes"
              };
          }
        }) || []
      );
    },
    makeMenu() {
      return [
        {
          title: "Analytics",
          icon: "fa fa-chart-bar",
          hidden:
            !this.newSidebarData?.analyticsConfig ||
            !hasAccessToRoute(routeNames.ANALYTICS_OVERVIEW),
          child: this.makeAnalyticsMenuItems,
          hiddenOnCollapse: true,
          exact: true
        },
        {
          title: "Manage Third Parties",
          icon: "fa fa-list-alt",
          hidden: !hasAccessToRoute(routeNames.MANAGE_THIRD_PARTY),
          href: urls.MANAGE_THIRD_PARTY,
          hiddenOnCollapse: true,
          exact: true
        },
        {
          icon: "fa fa-leaf",
          title: esgTexts.ESG_EXPLORE,
          hiddenOnCollapse: true,
          hidden: !hasAccessToRoute(routeNames.ESG_THIRD_PARTIES),
          child: [
            {
              href: urls.ESG_THIRD_PARTIES,
              title: esgTexts.ESG_THIRD_PARTIES,
              exact: true
            }
          ],
          exact: true
        },
        {
          title: "Event Log",
          icon: "fa fa-calendar-plus",
          href: urls.EVENT_LOG,
          hidden: !hasAccessToRoute(routeNames.EVENT_LOG),
          hiddenOnCollapse: true,
          exact: true
        },
        {
          title: "Tasks",
          icon: "fa fa-tasks",
          href: urls.TASKS,
          hidden: !hasAccessToRoute(routeNames.TASKS),
          hiddenOnCollapse: true,
          exact: true
        },
        {
          icon: "fa fa-user",
          title: "User Views",
          hidden: !this.companyViewMenuItems?.length,
          child: this.companyViewMenuItems,
          exact: true
        },
        {
          title: "Platform Settings",
          icon: "fa fa-cog",
          hidden: !hasAccessToRoute(routeNames.PLATFORM_SETTINGS),
          hiddenOnCollapse: true,
          exact: true,
          child: [
            {
              href: urls.USER_SETTINGS,
              title: "Users",
              exact: true
            },
            {
              href: urls.ROLES,
              title: "Roles",
              exact: true
            },
            {
              href: urls.PERMISSIONS,
              title: "Permissions",
              exact: true
            },
            {
              href: urls.NOTIFICATION_SETTINGS,
              title: "Notification Schemes",
              exact: true
            },
            {
              href: urls.BRANDING_AND_LINKS_SETTINGS,
              title: "Branding and links",
              exact: true
            },
            {
              href: urls.GENERAL_SETTINGS,
              title: "General",
              exact: true
            },
            {
              href: urls.TASK_TEMPLATES,
              title: "Task templates",
              hidden: !hasAccessToRoute(routeNames.TASK_TEMPLATES),
              exact: true
            },
            {
              href: urls.ACTIONS,
              title: "Actions",
              hidden: !hasAccessToRoute(routeNames.ACTIONS),
              exact: true
            },
            {
              href: urls.AUTOMATIONS,
              title: "Automations",
              hidden: !hasAccessToRoute(routeNames.AUTOMATIONS),
              exact: true
            },
            {
              href: urls.CUSTOM_ATTRIBUTES,
              title: "Custom Attributes",
              exact: true
            },
            {
              href: urls.EMAIL_TEMPLATE,
              title: "Email Templates",
              exact: true
            },
            {
              href: urls.USER_EMAIL_TEMPLATES,
              title: "User Email Templates",
              exact: true
            },
            {
              href: urls.PRODUCT_VERSION,
              title: "Product Version",
              hidden: !hasAccessToRoute(routeNames.PRODUCT_VERSION),
              exact: true
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations({
      setIsSidebarOpened: mutationName.NO_NAME_SPACE.SET_IS_SIDE_BAR_OPENED
    }),
    setAnalyticsMenuItemOptions({ menuTitle, childItem }) {
      switch (menuTitle) {
        case analyticsConfigurationViews.APRI:
          return {
            title: childItem?.title,
            href: urls.ANALYTICS_APRI,
            icon: "fa fa-chart-pie"
          };
        case analyticsConfigurationViews.OVERVIEW:
          return {
            title: childItem?.title,
            href: urls.ANALYTICS_OVERVIEW,
            icon: "fa fa-chart-line"
          };
        case analyticsConfigurationViews.QUESTIONNAIRES:
          return {
            title: childItem?.title,
            href: childItem?.title?.includes("Comparative")
              ? urls.ANALYTICS_QUESTIONNAIRES_COMPARATIVE(childItem?.id)
              : urls.ANALYTICS_QUESTIONNAIRES_ID(childItem?.id),
            icon: "fa fa-building"
          };
      }
    },
    onItemClick() {
      const singleFilteredFormData = JSON.parse(
        localStorage.getItem(localStorageItem.SINGLE_ANALYTICS)
      );
      if (singleFilteredFormData?.path !== this.$route.path) {
        localStorage.removeItem(localStorageItem.SINGLE_ANALYTICS);
      }

      const compareFilteredFormData = JSON.parse(
        localStorage.getItem(localStorageItem.COMPARE_ANALYTICS)
      );
      if (compareFilteredFormData?.path !== this.$route.path) {
        localStorage.removeItem(localStorageItem.COMPARE_ANALYTICS);
      }

      const dashboardViewFilteredFormData = JSON.parse(
        localStorage.getItem(localStorageItem.DASHBOARD_VIEW)
      );
      if (dashboardViewFilteredFormData?.path !== this.$route.path) {
        localStorage.removeItem(localStorageItem.DASHBOARD_VIEW);
      }
    },
    onSideBarVisibility(visibility) {
      if (this.isSideBarClosed) {
        this.setIsSidebarOpened(visibility);
      }
    },
    onToggleCollapse() {
      const isArrowClicked =
        this.sideBarArrowCssClasses === this.sideBarArrowCssClassAnimation;

      this.sideBarCssClasses = isArrowClicked
        ? ""
        : this.sideBarCssClassAnimation;
      this.sideBarArrowCssClasses = isArrowClicked
        ? ""
        : this.sideBarArrowCssClassAnimation;

      this.setIsSidebarOpened(isArrowClicked);

      if (!isArrowClicked) {
        setTimeout(() => {
          this.sideBarCssClasses += ` ${this.sideBarCssClassFinishedAnimation}`;
        }, timers.FAST);
      }
    }
  }
};
</script>

<style lang="scss">
.third-party-add-modal-postion-tooltip {
  position: absolute !important;
  will-change: transform !important;
  top: 3.4rem !important;
  left: 0px !important;
  transform: translate3d(65px, 90px, 0px) !important;
}

.third-party-mis-page-postion-tooltip {
  position: absolute !important;
  will-change: transform !important;
  top: 8.6rem !important;
  left: 0px !important;
  transform: translate3d(65px, 90px, 0px) !important;
}
.third-party-manage-postion-tooltip {
  position: absolute !important;
  will-change: transform !important;
  top: 14rem !important;
  left: 0px !important;
  transform: translate3d(65px, 90px, 0px) !important;
}
.company-settings-postion-tooltip {
  position: absolute !important;
  will-change: transform !important;
  top: 19.3rem !important;
  left: 0px !important;
  transform: translate3d(65px, 90px, 0px) !important;
}
.sidebar {
  position: fixed;
  z-index: 99;
  transition: $transition-duration;
  overflow: visible;

  &__wrapper {
    position: relative;

    &-menu {
      position: relative;
      width: 225px;
      height: calc(100vh - 255px);
      z-index: 1;
    }
  }

  &--bottom-shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
    z-index: 2;
  }

  &--slide-left {
    transform: translateX(calc(-100% + 30px));

    & .vsm--scroll-wrapper {
      opacity: 0;
    }
  }

  &--closed {
    &:hover {
      transform: translateX(0);

      & .vsm--scroll-wrapper {
        opacity: 1;
      }
    }
  }

  &__arrow {
    position: absolute;
    right: -15px;
    top: 10px;
    z-index: 1000;
    transition: $transition-duration;

    &--rotate {
      transform: rotate(180deg);
    }

    &-button {
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      background: transparent;
      border: 1px solid transparent;
    }
  }
}
.v-sidebar-menu .vsm--title {
  color: $black;
  font-weight: 450;
  padding-left: 30px;
  line-height: initial;
}

.v-sidebar-menu .vsm--menu {
  width: 225px;
}

.v-sidebar-menu .vsm--link.vsm--link_exact-active .vsm--title {
  font-weight: 550;
  color: $azure !important;
  background: $bright-gray;
  line-height: 20px;
}

.v-sidebar-menu.vsm_white-theme .vsm--dropdown .vsm--list {
  background-color: $ghost-white !important;
}
.v-sidebar-menu .vsm--title {
  -ms-flex-positive: 1;
  font-size: 13px;
  padding-left: 0px;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1 {
  color: $white;
  background-color: $bright-gray !important;
  font-weight: bolder !important;
}
.v-sidebar-menu.vsm_white-theme .vsm--link {
  white-space: normal;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
  box-shadow: 3px 0px 0px 0px $white inset !important;
  background: $bright-gray;
}

.v-sidebar-menu .vsm--icon {
  color: $spanish-gray !important;
  background: $ghost-white !important;
  width: 20px !important;
  height: 30px !important;
  margin-right: 12px;
}

.v-sidebar-menu .vsm--child .vsm--icon {
  width: 14px !important;
  height: 22px !important;
}

.v-sidebar-menu .vsm--arrow {
  color: $spanish-gray !important;
}

.v-sidebar-menu .vsm--arrow {
  color: $spanish-gray !important;
  width: fit-content !important;
  padding-right: 4px;
}

.v-sidebar-menu .vsm--link_open {
  background-color: inherit !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
  background-color: inherit !important;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1
  .vsm--icon {
  background-color: $bright-gray !important;
}
.v-sidebar-menu.vsm_white-theme .vsm--link {
  color: $spanish-gray !important;
}
.v-sidebar-menu .vsm--scroll-wrapper {
  height: 100%;
  margin-right: 0px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-sidebar-menu .vsm--dropdown > .vsm--list {
  padding: 0px !important;
}

.v-sidebar-menu .vsm--dropdown {
  padding: 0 0 0 24px !important;
}
.vsm--link .vsm--link_level-3 .vsm--link_active .vsm--link_exact-active {
  background-color: $bright-gray !important;
}
.v-sidebar-menu .vsm--link_level-1 {
  font-weight: bolder !important;
}
.v-sidebar-menu .vsm--link_level-2 {
  color: $white;
  background-color: $ghost-white !important;
  padding-left: 18px !important;
}
.v-sidebar-menu .vsm--link_level-2 .vsm--link_active {
  padding: 10px !important;
}
.v-sidebar-menu .vsm--link_level-2.vsm--link_exact-active {
  background: $bright-gray !important;
  margin-right: -9px;
}
.v-sidebar-menu .vsm--link_level-3 .vsm--link_active {
  color: $white;
  background-color: $bright-gray !important;
}
.vsm--link_active {
  background-color: $bright-gray !important;
  font-weight: bolder !important;
}

.v-sidebar-menu.vsm_collapsed .vsm--link {
  display: none;
}
.v-sidebar-menu.vsm_white-theme .vsm--toggle-btn {
  display: none !important;
}
.v-sidebar-menu .vsm--link.vsm--link_active.vsm--title {
  font-weight: bolder !important;
}
.v-sidebar-menu.vsm_white-theme .vsm--dropdown {
  background-color: $ghost-white !important;
}
.v-sidebar-menu .vsm--link_level-2.vsm--link_exact-active .vsm--title {
  margin-right: -9px;
}
.v-sidebar-menu .vsm--link {
  height: auto;
  padding: 10px !important;
}
</style>
